import { ComponentPropsWithRef, ElementType } from 'react';

import { Report } from '@/features/Reports/interfaces';

import { AppliedFilters } from './filters';

export type Nullable<T = undefined> = T | null;

export type ExcludesEmpty = <T>(x: T | undefined | null) => x is T;

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type MergeElementProps<
  T extends ElementType,
  // Check this comment: https://github.com/microsoft/TypeScript/issues/21732#issuecomment-886221640
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends object = {},
> = Omit<ComponentPropsWithRef<T>, keyof P> & P;

export enum ThemeVariant {
  AUTO = 'auto',
  LIGHT = 'light',
  DARK = 'dark',
}

// This has to be in sync with the API_ACCESS enum
export enum ReportSourceType {
  HORIZON = 'Horizon',
  LOOKOUT = 'Lookout V2',
}

export enum ReportImpact {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  EXTREME = 'Extreme',
}

export enum ReportTypeCategory {
  INCIDENT = 'Incident',
  INFORMATION = 'Information',
}

export enum ReportCategory {
  AUTOMOTIVE = 'Automotive',
  AVALANCHE = 'Avalanche',
  AVIATION = 'Aviation',
  BORDER_INCIDENT = 'Border incident',
  CHEMICAL = 'Chemical',
  CIVIL_UNREST = 'Civil unrest',
  CIVIL_WAR = 'Civil war',
  CONSTRUCTION = 'Construction',
  CORRUPTION = 'Corruption',
  CRIME = 'Crime',
  CYBERSECURITY_POLICY = 'Cybersecurity policy',
  DATA_BREACH = 'Data breach',
  DDOS = 'DDoS',
  DIPLOMACY = 'Diplomacy',
  DROUGHT = 'Drought',
  EARTHQUAKE = 'Earthquake',
  ECONOMIC_TREND = 'Economic trend',
  ELECTION = 'Election',
  ELECTRICITY = 'Electricity',
  FLOOD = 'Flood',
  GROUNDWATER_POLLUTION = 'Groundwater pollution',
  HEALTH_EPIDEMIC = 'Health & Epidemic',
  HEATWAVE = 'Heatwave',
  HOSPITALITY_TOURISM = 'Hospitality & Tourism',
  KIDNAPPING = 'Kidnapping',
  LANDSLIDE = 'Landslide',
  MIGRATION = 'Migration',
  MILITARY_EXERCISE = 'Military exercise',
  MINING = 'Mining',
  NUCLEAR = 'Nuclear',
  OIL_GAS = 'Oil & gas',
  PHISHING = 'Phishing',
  PIRACY = 'Piracy',
  RANSOMWARE = 'Ransomware',
  RENEWABLE_ENERGY = 'Renewable Energy',
  SEVERE_COLD_WEATHER = 'Severe cold weather',
  SOCIAL_TROUBLE = 'Social trouble',
  SPORT_ENTERTAINMENT = 'Sport & Entertainment',
  STORM = 'Storm',
  STRIKE = 'Strike',
  TELECOM = 'Telecom',
  TERRORISM_INSURGENCY = 'Terrorism / Insurgency',
  TRADE_AGREEMENT = 'Trade agreement',
  TRANSPORT_LOGISTICS = 'Transport & Logistics',
  TSUNAMI = 'Tsunami',
  VOLCANIC_ERUPTION = 'Volcanic eruption',
  VULNERABILITY = 'Vulnerability',
  WAR = 'War',
  WATER_FOOD = 'Water & Food',
  WILDFIRE = 'Wildfire',
  WORKPLACE_DISASTER = 'Workplace disaster',
  TRAVEL = 'Travel warning',
}

export type ViewState = {
  longitude: number;
  latitude: number;
  zoom: number;
};

export type Coordinates = {
  lat: number;
  lng: number;
};

export type RadialCenter = [number, number];

export type PointCoordinates = [number, number];

export enum UserRole {
  MANAGEMENT_ADMIN = 'Management Admin',
  MANAGEMENT_EDITOR = 'Management Editor',
  MANAGEMENT_ANALYST = 'Management Analyst',
  MANAGEMENT_JUNIOR_ANALYST = 'Management Junior Analyst',
  TEAM_LEADER = 'Team Leader',
  ANALYST = 'Analyst',
  VIEWER = 'Viewer',
}

// This has to be in sync with the ReportSourceType enum
export enum ApiAccess {
  LOOKOUT = 'Lookout V2',
  HORIZON = 'Horizon',
  RISK_ASSESSMENT = 'Risk Assessment',
  EMBASSY_DETAILS = 'Embassy Details',
}

export enum NotificationType {
  IN_APP = 'In-app',
  EMAIL = 'Email',
}

export type AddressPredictionResponse = {
  predictions: Array<{
    description: string;
    matchedSubstrings: Array<{
      length: number;
      offset: number;
    }>;
    placeId: string;
    reference: string;
    structuredFormatting: {
      mainText: string;
      mainTextMatchedSubstrings: Array<{
        length: number;
        offset: number;
      }>;
      secondaryText: string;
    };
    terms: Array<{
      offset: number;
      value: string;
    }>;
    types: Array<string>;
  }>;
  status: string;
};

export type AddressDetailsResponse = {
  htmlAttributions: Array<unknown>;
  result: {
    formattedAddress: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
      viewport: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
    };
    name: string;
  };
  status: string;
};

export type ExcludeEmpty = <T>(x: T | false | null | undefined) => x is T;

export type Multicolumn = {
  id: number;
  name: string;
  horizonReports: Array<Report>;
  lookoutReports: Array<Report>;
  filters?: AppliedFilters;
};

export enum AppAccess {
  PRODUCTION = 'Production',
  BETA = 'Beta',
  ADVANCED_FEATURES = 'Advanced Features',
}

export type SharedUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  groups: Array<UserRole>;
  organization: {
    id: string;
    name: string;
  };
};

export type SharedOrganization = {
  description: string;
  id: string;
  isManagement: boolean;
  name: string;
};
