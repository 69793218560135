import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSession } from 'next-auth/react';
import { unwrapResult } from '@reduxjs/toolkit';
import { bbox, multiPoint } from '@turf/turf';

import { useAppDispatch } from '@/core/store/store';
import { useAuthenticatedSession } from '@/core/hooks/useAuthenticatedSession';
import { ApiAccess, ReportSourceType } from '@/core/interfaces/common';
import { getCategories, getCountries } from '@/core/store/actions/config';
import { getAllAppliedFiltersSelector } from '@/core/store/reducers/filtersSlice';
import { LoginStatesValues } from '@/core/constants/constants';
import { getCategoriesSelector, getCountriesSelector } from '@/core/store/reducers/config';
import { MapContext } from '@/core/context/MapContext';

import { getUserSelector } from '@/features/Auth/store';
import { getFocusesList, getSharedFocusesList } from '@/features/Focus/store';
import {
  appendHorizonReports,
  appendLookoutReports,
  getHorizonReportsList,
  getHorizonReportsListSelector,
  getLookoutReportsList,
  getLookoutReportsListSelector,
} from '@/features/Reports/store';
import { ReportResponseItem } from '@/features/Reports/interfaces';

import { transformAppliedFiltersToPostData } from '@/utils/filters';

export const GetInitialData = () => {
  const { mapRef } = useContext(MapContext);
  const fetchReportsInterval = useRef<NodeJS.Timeout | null>(null);
  const user = useSelector(getUserSelector);
  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const horizonReports = useSelector(getHorizonReportsListSelector);
  const lookoutReports = useSelector(getLookoutReportsListSelector);
  const dispatch = useAppDispatch();
  const { status } = useSession();
  const categories = useSelector(getCategoriesSelector);
  const countries = useSelector(getCountriesSelector);

  useAuthenticatedSession(() => {
    if (!user) {
      return;
    }

    dispatch(getCategories());
    dispatch(getCountries());
  }, []);

  // Fetch focuses and shared focuses when we have categories and countries to map
  useEffect(() => {
    if (categories.length && countries.length) {
      dispatch(getFocusesList());
      dispatch(getSharedFocusesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, countries]);

  const hasHorizonFilter = useMemo(
    () =>
      appliedFilters.moreFilters?.some(filterItem => filterItem.value === ReportSourceType.HORIZON),
    [appliedFilters.moreFilters]
  );

  const hasLookoutFilter = useMemo(
    () =>
      appliedFilters.moreFilters?.some(filterItem => filterItem.value === ReportSourceType.LOOKOUT),
    [appliedFilters.moreFilters]
  );

  const shouldFetchHorizonReports = useMemo(
    () =>
      user?.organization.currentSubscription.apiAccess.includes(ApiAccess.HORIZON) &&
      (hasHorizonFilter || (!hasHorizonFilter && !hasLookoutFilter)),
    [hasHorizonFilter, hasLookoutFilter, user?.organization.currentSubscription.apiAccess]
  );

  const shouldFetchLookoutReports = useMemo(
    () =>
      user?.organization.currentSubscription.apiAccess.includes(ApiAccess.LOOKOUT) &&
      (hasLookoutFilter || (!hasHorizonFilter && !hasLookoutFilter)),
    [hasHorizonFilter, hasLookoutFilter, user?.organization.currentSubscription.apiAccess]
  );

  const reportFiltersData = useMemo(
    () => transformAppliedFiltersToPostData(appliedFilters),
    [appliedFilters]
  );

  useEffect(() => {
    const promisesList: Array<unknown> = [];

    if (status === LoginStatesValues.AUTHENTICATED && user) {
      if (shouldFetchHorizonReports) {
        promisesList.push(dispatch(getHorizonReportsList(reportFiltersData)));
      }

      if (shouldFetchLookoutReports) {
        promisesList.push(dispatch(getLookoutReportsList(reportFiltersData)));
      }
    }

    Promise.all(promisesList).then(fetchPromises => {
      // @ts-expect-error promise is a redux dispatch and `unwrapResult` works
      const results = fetchPromises.flatMap(unwrapResult) as Array<ReportResponseItem>;

      const reportsToFit = results
        .map(reportItem =>
          reportItem.location.map(reportLocation => [
            reportLocation.longitude,
            reportLocation.latitude,
          ])
        )
        .flat();

      const caluclatedBbox = bbox(multiPoint(reportsToFit));

      // @ts-expect-error BBox has correct return type
      mapRef?.getMap()?.fitBounds(caluclatedBbox, {
        padding: 50,
      });
    });

    return () => {
      promisesList.forEach(promise => {
        // @ts-expect-error abort exists on Promise
        promise.abort();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, status]);

  const fetchHorizonReports = useCallback(
    (pageNumber: number) => {
      dispatch(
        getHorizonReportsList({
          ...reportFiltersData,
          page: pageNumber,
        })
      )
        .unwrap()
        .then(response => {
          const newReports = response.filter(
            report =>
              !horizonReports.some(horizonReport => horizonReport.reportId === report.reportId)
          );

          if (newReports.length > 0) {
            dispatch(appendHorizonReports(newReports));
          }

          if (newReports.length === response.length && response.length > 0) {
            fetchHorizonReports(pageNumber + 1);
          }
        });
    },
    [dispatch, horizonReports, reportFiltersData]
  );

  const fetchLookoutReports = useCallback(
    (pageNumber: number) => {
      dispatch(
        getLookoutReportsList({
          ...reportFiltersData,
          page: pageNumber,
        })
      )
        .unwrap()
        .then(response => {
          const newReports = response.filter(
            report =>
              !lookoutReports.some(lookoutReport => lookoutReport.reportId === report.reportId)
          );

          if (newReports.length > 0) {
            dispatch(appendLookoutReports(newReports));
          }

          if (newReports.length === response.length && response.length > 0) {
            fetchLookoutReports(pageNumber + 1);
          }
        });
    },
    [dispatch, lookoutReports, reportFiltersData]
  );

  const refetchNewReports = useCallback(async () => {
    const pageNumber = 1;

    if (shouldFetchHorizonReports) {
      fetchHorizonReports(pageNumber);
    }

    if (shouldFetchLookoutReports) {
      fetchLookoutReports(pageNumber);
    }
  }, [
    fetchHorizonReports,
    fetchLookoutReports,
    shouldFetchHorizonReports,
    shouldFetchLookoutReports,
  ]);

  useEffect(() => {
    fetchReportsInterval.current = setInterval(() => {
      refetchNewReports();
    }, 1000 * 30);

    return () => {
      if (fetchReportsInterval.current) {
        clearInterval(fetchReportsInterval.current);
      }
    };
  }, [refetchNewReports]);

  return null;
};
