import ArrowLeft from '@/core/components/Icons/svg/arrow-left.svg';
import BellOn from '@/core/components/Icons/svg/bell-on.svg';
import ChevronDown from '@/core/components/Icons/svg/chevron-down.svg';
import ChevronLeft from '@/core/components/Icons/svg/chevron-left.svg';
import ChevronRight from '@/core/components/Icons/svg/chevron-right.svg';
import CircleInfo from '@/core/components/Icons/svg/circle-info.svg';
import ChevronUp from '@/core/components/Icons/svg/chevron-up.svg';
import Close from '@/core/components/Icons/svg/close.svg';
import Copy from '@/core/components/Icons/svg/copy.svg';
import Ellipsis from '@/core/components/Icons/svg/ellipsis.svg';
import Eye from '@/core/components/Icons/svg/eye.svg';
import Gear from '@/core/components/Icons/svg/gear.svg';
import Heatmap from '@/core/components/Icons/svg/heatmap.svg';
import Impact from '@/core/components/Icons/svg/impact.svg';
import InfoCircle from '@/core/components/Icons/svg/info-circle.svg';
import Shape from '@/core/components/Icons/svg/shape.svg';
import Search from '@/core/components/Icons/svg/search.svg';
import Strike from '@/core/components/Icons/svg/strike.svg';
import ChartLineUp from '@/core/components/Icons/svg/chart-line-up.svg';
import BinaryLock from '@/core/components/Icons/svg/binary-lock.svg';
import HouseWater from '@/core/components/Icons/svg/house-water.svg';
import Building from '@/core/components/Icons/svg/building.svg';
import LandmarkDome from '@/core/components/Icons/svg/landmark-dome.svg';
import HelmetSafety from '@/core/components/Icons/svg/helmet-safety.svg';
import SirenOn from '@/core/components/Icons/svg/siren-on.svg';
import Passport from '@/core/components/Icons/svg/passport.svg';
import Check from '@/core/components/Icons/svg/check.svg';
import MicrochipAi from '@/core/components/Icons/svg/microchip-ai.svg';
import Person from '@/core/components/Icons/svg/person.svg';
import Plus from '@/core/components/Icons/svg/plus.svg';
import Minus from '@/core/components/Icons/svg/minus.svg';
import Trash from '@/core/components/Icons/svg/trash.svg';
import HouseChimneyWindow from '@/core/components/Icons/svg/house-chimney-window.svg';
import Warehouse from '@/core/components/Icons/svg/warehouse.svg';
import Flag from '@/core/components/Icons/svg/flag.svg';
import PersonLine from '@/core/components/Icons/svg/person-line.svg';
import Phone from '@/core/components/Icons/svg/phone.svg';
import Hearth from '@/core/components/Icons/svg/heart.svg';
import Star from '@/core/components/Icons/svg/star.svg';
import Plane from '@/core/components/Icons/svg/plane.svg';
import LocationPin from '@/core/components/Icons/svg/location-pin.svg';
import LocationDot from '@/core/components/Icons/svg/location-dot.svg';
import Globe from '@/core/components/Icons/svg/globe.svg';
import Lock from '@/core/components/Icons/svg/lock.svg';
import Thumbtack from '@/core/components/Icons/svg/thumbtack.svg';
import EarthAmericas from '@/core/components/Icons/svg/earth-americas.svg';
import Truck from '@/core/components/Icons/svg/truck.svg';
import Train from '@/core/components/Icons/svg/train.svg';
import Car from '@/core/components/Icons/svg/car.svg';
import Ship from '@/core/components/Icons/svg/ship.svg';
import UpToLine from '@/core/components/Icons/svg/up-to-line.svg';
import FolderPlus from '@/core/components/Icons/svg/folder-plus.svg';
import ArrowRight from '@/core/components/Icons/svg/arrow-right.svg';
import EyeSlash from '@/core/components/Icons/svg/eye-slash.svg';
import AlertAttentionDangerShield from '@/core/components/Icons/svg/alert-attention-danger-shield-exclamation.svg';
import AlertAttentionDangerTriangle from '@/core/components/Icons/svg/alert-attention-danger-triangle-exclamation.svg';
import BiohazardHazard from '@/core/components/Icons/svg/biohazard-hazard.svg';
import BuildingBank from '@/core/components/Icons/svg/building-bank.svg';
import BuildingEmbassy from '@/core/components/Icons/svg/building-embassy.svg';
import BuildingGovernment from '@/core/components/Icons/svg/building-government.svg';
import BuildingHospital from '@/core/components/Icons/svg/building-hospital.svg';
import BuildingHotel from '@/core/components/Icons/svg/building-hotel.svg';
import BuildingHouseHomeUser from '@/core/components/Icons/svg/building-house-home-user.svg';
import BuildingHouseHome from '@/core/components/Icons/svg/building-house-home.svg';
import BuildingOffice from '@/core/components/Icons/svg/building-office.svg';
import BuildingSchoolUniversityCollege from '@/core/components/Icons/svg/building-school-university-college.svg';
import ChemicalPharmaceuticalLaboratory from '@/core/components/Icons/svg/chemical-pharmaceutical-laboratory.svg';
import ConstructionSiteExcavator from '@/core/components/Icons/svg/construction-site-excavator.svg';
import CourtSportStadium from '@/core/components/Icons/svg/court-sport-stadium.svg';
import DatabaseDataDataCenter from '@/core/components/Icons/svg/database-data-data-center.svg';
import Explosion from '@/core/components/Icons/svg/explosion.svg';
import FenceBorderFrontier from '@/core/components/Icons/svg/fence-border-frontier.svg';
import GlobeEarth from '@/core/components/Icons/svg/globe-earth.svg';
import IndustryFactoryProduction from '@/core/components/Icons/svg/industry-factory-production.svg';
import LocationDotPinMap from '@/core/components/Icons/svg/location-dot-pin-map.svg';
import NuclearPowerplantAtom from '@/core/components/Icons/svg/nuclear-powerplant-atom.svg';
import NuclearPowerplant from '@/core/components/Icons/svg/nuclear-powerplant.svg';
import NuclearRadiationHazard from '@/core/components/Icons/svg/nuclear-radiation-hazard.svg';
import OilGas from '@/core/components/Icons/svg/oil-gas.svg';
import OilPlatformGasRig from '@/core/components/Icons/svg/oil-platform-gas-rig.svg';
import PassportBorderFrontier from '@/core/components/Icons/svg/passport-border-frontier.svg';
import PersonPeopleUsersGroup from '@/core/components/Icons/svg/person-people-users-group.svg';
import PersonPeopleUsers from '@/core/components/Icons/svg/person-people-users.svg';
import PersonPoliceSecurity from '@/core/components/Icons/svg/person-police-security.svg';
import PersonUser from '@/core/components/Icons/svg/person-user.svg';
import PetrolGasPump from '@/core/components/Icons/svg/petrol-gas-pump.svg';
import PlaneAirportDeparture from '@/core/components/Icons/svg/plane-airport-departure.svg';
import PlaneUp from '@/core/components/Icons/svg/plane-up.svg';
import RefugeeCampVillage from '@/core/components/Icons/svg/refugee-camp-village.svg';
import ShipBoat from '@/core/components/Icons/svg/ship-boat.svg';
import ShipCranePortTerminal from '@/core/components/Icons/svg/ship-crane-port-terminal.svg';
import ShipPortHarborHarbourFerryBoatCargo from '@/core/components/Icons/svg/ship-port-harbor-harbour-ferry-boat-cargo.svg';
import StarFavourite from '@/core/components/Icons/svg/star-favourite.svg';
import TargetCrosshairsDot from '@/core/components/Icons/svg/target-crosshairs-dot.svg';
import TargetCrosshairsSimple from '@/core/components/Icons/svg/target-crosshairs-simple.svg';
import TrainTrainstationSubwayRailway from '@/core/components/Icons/svg/train-trainstation-subway-railway.svg';
import WarehouseStorageFullfillmentSupplier from '@/core/components/Icons/svg/warehouse-storage-fullfillment-supplier.svg';
import WarehouseSupplier from '@/core/components/Icons/svg/warehouse-supplier.svg';
import Folder from '@/core/components/Icons/svg/folder.svg';
import Upload from '@/core/components/Icons/svg/upload.svg';
import PenToSquare from '@/core/components/Icons/svg/pen-to-square.svg';

export const svgList = {
  ArrowLeft,
  BellOn,
  BinaryLock,
  Building,
  ChartLineUp,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleInfo,
  ChevronUp,
  Close,
  Copy,
  Ellipsis,
  Eye,
  Gear,
  Heatmap,
  HelmetSafety,
  HouseWater,
  Impact,
  InfoCircle,
  LandmarkDome,
  MicrochipAi,
  Minus,
  Passport,
  Person,
  Plus,
  Search,
  Shape,
  SirenOn,
  Strike,
  Trash,
  HouseChimneyWindow,
  Warehouse,
  Flag,
  PersonLine,
  Phone,
  Hearth,
  Star,
  Plane,
  LocationPin,
  LocationDot,
  Globe,
  Lock,
  Thumbtack,
  EarthAmericas,
  Truck,
  Train,
  Car,
  Ship,
  UpToLine,
  FolderPlus,
  ArrowRight,
  EyeSlash,
  AlertAttentionDangerShield,
  AlertAttentionDangerTriangle,
  BiohazardHazard,
  BuildingBank,
  BuildingEmbassy,
  BuildingGovernment,
  BuildingHospital,
  BuildingHotel,
  BuildingHouseHomeUser,
  BuildingHouseHome,
  BuildingOffice,
  BuildingSchoolUniversityCollege,
  ChemicalPharmaceuticalLaboratory,
  ConstructionSiteExcavator,
  CourtSportStadium,
  DatabaseDataDataCenter,
  Explosion,
  FenceBorderFrontier,
  GlobeEarth,
  IndustryFactoryProduction,
  LocationDotPinMap,
  NuclearPowerplantAtom,
  NuclearPowerplant,
  NuclearRadiationHazard,
  OilGas,
  OilPlatformGasRig,
  PassportBorderFrontier,
  PersonPeopleUsersGroup,
  PersonPeopleUsers,
  PersonPoliceSecurity,
  PersonUser,
  PetrolGasPump,
  PlaneAirportDeparture,
  PlaneUp,
  RefugeeCampVillage,
  ShipBoat,
  ShipCranePortTerminal,
  ShipPortHarborHarbourFerryBoatCargo,
  StarFavourite,
  TargetCrosshairsDot,
  TargetCrosshairsSimple,
  TrainTrainstationSubwayRailway,
  WarehouseStorageFullfillmentSupplier,
  WarehouseSupplier,
  Folder,
  Upload,
  PenToSquare,
};
